.checkout-data{
    max-width: 70%;
    margin: auto;
}

.checkout-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    
}

.delete{
    font-size: 1.7rem;
    border: none;
}
.delete:hover{
    color: darkred !important; 
}
.prod-pic{
    width: 72px;
    height: 87px;
}

.qty-btn{
    border: 2px solid grey;
    font-size: 20px;
    max-width: 42%;
    max-height: 42%;
    background-color: white;
}
.qty-btn:hover{
    background-color: lightgrey;
}

.qty {
    border: 2px solid grey;
    max-width: 40%;
    height: 36px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-left{
    border-left: none;
}

.border-right{
    border-right: none;
}

.left-radius{
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.right-radius{
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.checkout-detail{
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    font-size: 20px;
}

.cart-amount-total{
    max-width: 300px;
    border: 2px solid grey;
    height: 210px;
}
.btn-add{
    width: 100%;
}
.trash{
    width: 20px;
    height: 20px;
    float: left;
    cursor: pointer;
    background-color: #f8f9fa;
}
.trash:hover{
    color: rgb(182, 36, 36);
}