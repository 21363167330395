.uparrow{
    position: relative;
    right: -15px;
    top: 0px;
    cursor: pointer;
    font-size: 18px !important;
}
.uparrow:hover{
    color: red !important;
}

.downarrow{
    position: relative;
    right: 7px;
    top: 0px;
    cursor: pointer;
    font-size: 18px !important;
}
.downarrow:hover{
    color: red !important;
}

.image-data{
    width: 50px;
    height: 50px;
}

.quantity{
    max-width: 35px;
    margin: auto;
    border: 2px solid lightgray;
    margin: 0;
    color: grey;
}

.buy-item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart{
    width: 25px;
    height: 25px;
    color: grey;
    cursor: pointer;
}
.cart:hover{
    color: darkgray;
}
.checkbox{
    width: 15px;
    height: 15px;
}