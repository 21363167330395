.product-listing {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: auto;
}

.filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.search-add {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.input-width {
    max-width: 35%;
}

.input-width::placeholder {
    text-align: center;
}

.select-width {
    max-width: 25%;
}

.btn-filter {
    width: 100px;
    box-shadow: 0 1px 5px rgba(70, 49, 74, 0.8);
}

.btn-filter-size {
    width: 130px;
    box-shadow: 0 1px 5px rgba(70, 49, 74, 0.8);
}

.btn-filter-type {
    width: 100%;
}

.btn-cart {
    font-size: 40px !important;
}

.btn-cart:hover {
    color: gray !important;
}

.Qty-indicator {
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    right: 15px;
    top: -5px;
    color: black;
}

.container-filter{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 41px;
    box-shadow: 0 1px 5px rgba(70, 49, 74, 0.8);
    z-index: 1;
}

.type-container {
    left: 11px;
}

.size-container{
    left: 35px;
    width: 50px;
}

.btn-type:hover{
    background-color: #e9ecef !important;
}